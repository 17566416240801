import { axiosProvider } from "../configs/axios.config";
import { messageNotify, clearNotify } from "../redux/action/action.global";
import {
  ActionGetPlantOverview, ActionClearPlantOverview, ActionGetInitialDataIssued,
  ActionClearInitialDataIssued, ActionClearAllAbnormalItems, ActionGetAllAbnormalItems,
  ActionGetHealthScore, ActionClearHealthScore, ActionGetAnomaly,
  ActionClearAnomaly, ActionGetEquipmentOverviewByPlantEnhance, ActionClearEquipmentOverviewByPlantEnhance,
  ActionGetPlantStatus, ActionClearPlantStatus, ActionGetSensor,
  ActionClearSensor, ActionGetLoadType, ActionClearLoadType,ActionClearRanking,ActionClearSensorSum,
  ActionGetDetail, ActionClearDetail, ActionClearLogUpdateIssueLast, ActionGetLogUpdateIssueLast,
  ActionClearIssueSummary, ActionEnhGetIssueSummary,
  ActionGetUserEditingRoles, ActionClearUserEditingRoles,
  ActionDownloadManualFile, ActionDownloadManualFileSuccess, ActionGetSensorSum,ActionGetRanking, ActionGetRuntime,ActionClearRuntime,
  ActionClearAlert, ActionGetAlert, ActionClearMLIssue,ActionGetMLIssue, ActionClearSensorV2, ActionGetSensorV2, ActionGetEquip, ActionGetByparam,
  ActionClearByparam,
  ActionClearDateRank,
  ActionGetDateRank,
  ActionClearDate,
  ActionGetDate
} from "../redux/action/action.machine.learning.jsx";
import { BASE_URL } from "../configs/constant.key";
const Controller = "/MachineLearning";

function dispatchGlobalNotify(dispatch, resp) {
  dispatch(
    messageNotify({
      status: resp.code,
      message: resp.message,
    })
  );
}

export const ServiceGetModelOutput = async (resource) => {
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/GetModelOutput`,
      resource
    );
    return resp.data.data;
  } catch { }
};
export const ServiceGetPiData = async (resource) => {
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/GetPiData`,
      resource
    );
    return resp.data.data;
  } catch { }
};

export const ServiceGetPlantOverview = (resource) => async (dispatch) => {
  dispatch(ActionClearPlantOverview());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/GetPlantOverviewEnhance`
    );
    dispatch(ActionGetPlantOverview(resp.data));
  } catch { }
};

export const ServiceGetAllAbnormalItems = (resource) => async (dispatch) => {
  dispatch(ActionClearAllAbnormalItems());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetPlantAll`
    );
    dispatch(ActionGetAllAbnormalItems(resp.data));
    console.log("Test issure")
  } catch { }
};

export const ServiceGetInitialDataIssued =
  (plantCode) => async (dispatch) => {
    dispatch(ActionClearInitialDataIssued());
    try {
      const resp = await axiosProvider.get(
        `${BASE_URL}${Controller}/GetInitialDataIssuedEnhance/${plantCode}`
      );
      dispatch(ActionGetInitialDataIssued(resp.data));
    } catch { }
  };

export const ServicePostUpdateIssued =
  (resource) => async (dispatch) => {
    try {
      const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/EnhUpdateIssued`,
        resource
      );
      const respData = resp.data;
      console.log('respData :', respData);
      if (respData.result.code !== 200) {
        dispatchGlobalNotify(dispatch, respData.result);
      }
    } catch { }
  };

export const ServiceGetEquipmentOverviewByPlantEnhance =
  (resource) => async (dispatch) => {
    dispatch(ActionClearEquipmentOverviewByPlantEnhance());
    try {
      const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/GetEquipmentOverviewByPlantEnhance`,
        resource
      );
      dispatch(ActionGetEquipmentOverviewByPlantEnhance(resp.data));
    } catch { }
  };
export const ServiceGetHealthScore = (resource) => async (dispatch) => {
  dispatch(ActionClearHealthScore());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetHealthScore`,
      resource
    );
    dispatch(ActionGetHealthScore(resp.data));
  } catch { }
};
export const ServiceGetAnomaly = (resource) => async (dispatch) => {
  dispatch(ActionClearAnomaly());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetAnomaly`,
      resource
    );
    dispatch(ActionGetAnomaly(resp.data));
  } catch { }
};

export const ServiceGetPlantStatus = (resource) => async (dispatch) => {
  dispatch(ActionClearPlantStatus());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetPlantStatus`,
      resource
    );
    dispatch(ActionGetPlantStatus(resp.data));
  } catch { }
};
export const ServiceGetSensor = (resource) => async (dispatch) => {
  dispatch(ActionClearSensor());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetSensor`,
      resource
    );
    //  console.log('ServiceGetSensor',resp)
     dispatch(ActionGetSensor(resp.data));
  } catch { }
};
export const ServiceGetRanking= (resource) => async (dispatch) => {
  dispatch(ActionClearRanking());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetRank`,
      resource
    );
    // console.log('ServiceGetEnhGetRank',resp)
     dispatch(ActionGetRanking(resp.data));
  } catch { }
};

export const ServiceGetRumtime= () => async (dispatch) => {
  dispatch(ActionClearRuntime());
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhGetRumtime`,
      
    );
    
     dispatch(ActionGetRuntime(resp.data));
  } catch { }
};

export const ServiceGetAlert= () => async (dispatch) => {
  dispatch(ActionClearAlert());
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhGetAlert`,
      
    );
    
     dispatch(ActionGetAlert(resp.data));
  } catch { }
};
export const ServiceGetSensorSum = (resource) => async (dispatch) => {
  dispatch(ActionClearSensorSum());
  try {
    const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/EnhGetSensorSum`,
        resource
    );
    // console.log('ServiceGetSensorSum',resp)
    dispatch(ActionGetSensorSum(resp.data));
  } catch { }
};
export const ServiceGetSensorV2 = (resource) => async (dispatch) => {
  dispatch(ActionClearSensorV2());
  try {
    const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/EnhGetSensorV2`,
        resource
    );
    // console.log('ServiceGetSensorEnhGetSensorV2',resp)    
    dispatch(ActionGetSensorV2(resp.data));
  } catch { }
};
export const ServiceEnhGetSensorByparam = (resource) => async (dispatch) => {
  dispatch(ActionClearByparam());
  try {
    const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/EnhGetSensorByparam`,
        resource
    );
    //  console.log('EnhGetSensorByparam',resp)    
    dispatch(ActionGetByparam(resp.data));
  } catch { }
};
export const ServiceGetLoadType = (resource) => async (dispatch) => {
  dispatch(ActionClearLoadType());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetLoadType`,
      resource
    );
    dispatch(ActionGetLoadType(resp.data));
  } catch { }
};
export const ServiceGetIssure= (resource) => async (dispatch) => {
  dispatch(ActionClearMLIssue());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhMLlssue`,
      resource
    );
    
     dispatch(ActionGetMLIssue(resp.data));
  } catch { }
};

export const ServiceGetIssureCsase = async () => {
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhMLlssueCase`,
    );
    return resp.data
  } catch { }
};

export const ServiceGetSensorlog = async () => {
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhGetSensorStatus`,
    );
    return resp.data
  } catch { }
};

export const ServiceGetImpatEquip = async () => {
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhGetImpactEquip`,
    );
    return resp.data
  } catch { }
};

export const ServiceGetPiService = async () => {
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhGetPiService`,
    );
    return resp.data
  } catch { }
}

export const ServiceGetExcelReport = async (dateStart, dateEnd) => {
  try {
    const payload = { dateStart, dateEnd }
    console.log('payload', payload)
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/GetExportexcelList`,
      payload
    );
    return resp.data
  } catch { }
}

export const ServiceGetIssurebyEquipID = async (resource) => {
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhMLlssueByEquid`,
      resource
    );
    
     return resp.data
  } catch { }
};

export const ServiceEnhGetAllEquip = async(resource) => {
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhGetAllEquip/${resource}`,
    );
    //  console.log('EnhGetAllEquip',resp.data)
    // dispatch(ActionGetEquip(resp.data));
    return resp.data;
  } catch { }
};

export const ServiceGetDetail = (resource) => async (dispatch) => {
  dispatch(ActionClearDetail());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetDetail`,
      resource
    );
    dispatch(ActionGetDetail(resp.data));
  } catch { }
};

export const ServiceGetSensorImg = (resource) => async (dispatch) => {
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/EnhGetSenserImg`,
      resource
    );

    return resp.data;
  } catch { }
};

export const ServiceGetDateRank = (resource) => async (dispatch) => {
  dispatch(ActionClearDateRank())
  try {
    const resp = resource;
    console.log("respDateRedux",resp)

    dispatch(ActionGetDateRank(resp));
  } catch { }
}


export const ServiceGetDate = (resource) => async (dispatch) => {
  dispatch(ActionClearDate())
  try {
    const resp = resource;
    console.log("respDateRedux",resp)

    dispatch(ActionGetDate(resp));
  } catch { }
}

export const ServiceGetLogUpdateIssueLast = (resource) => async (dispatch) => {
  dispatch(ActionClearLogUpdateIssueLast());
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/EnhIssuedLast/${resource}`
    );
    dispatch(ActionGetLogUpdateIssueLast(resp.data));
  } catch { }
};
export const ServiceEnhGetIssueSummary =
  (resource) => async (dispatch) => {
    dispatch(ActionClearIssueSummary());
    try {
      const resp = await axiosProvider.get(
        `${BASE_URL}${Controller}/EnhGetIssueSummary/${resource}`
      );
      dispatch(ActionEnhGetIssueSummary(resp.data));
    } catch { }
  };


export const ServiceGetUserEditingRoles =
  (resource) => async (dispatch) => {
    dispatch(ActionClearUserEditingRoles());
    try {
      const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/GetUserEditingRoles`
      );
      dispatch(ActionGetUserEditingRoles(resp.data));
    } catch { }
  };

export const ServiceDownloadManualFile =
  () => async (dispatch) => {

    dispatch(ActionDownloadManualFile());
    try {
      const resp = await axiosProvider.post(
        `${BASE_URL}${Controller}/GetManualDocFile`
      );
      const respData = resp.data;
      if (respData.result.code !== 200) {
        dispatchGlobalNotify(dispatch, respData.result);
      }
      else {
        const url = respData.data.fileBase64;
        const a = document.createElement("a");
        a.href = url;
        a.download = respData.data.fileName;
        a.click();
      }
      dispatch(ActionDownloadManualFileSuccess());
    } catch { }
  };


