import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const AnomalyChart = ({ anomalyData, date }) => {
  const chartContainerRef = useRef(null);

      // console.log("Graph Sensor")

  let data = anomalyData.map((item) => ({
    date: Math.floor(new Date(item.paramDatetime).getTime() / 1000) * 1000,
    value1: item.predictValue,
    value2: item.paramValue,
    paramcode: item.paramCode,
    paramUnit: item.paramUnit,
    paramDatetime: Math.floor(new Date(item.paramDatetime).getTime() / 1000) * 1000,
  })).sort((x, y) => x.paramDatetime - y.paramDatetime);

  useEffect(() => {
    let root = am5.Root.new(chartContainerRef.current);

    root.setThemes([am5themes_Animated.new(root)]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd hh:mm",
      dateFields: ["valueX"],
    });

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
    }));

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "minute",
          count: 5,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        min: "0",
        max: "paramValue",
        strictMinMax: false,
        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom" }),
      })
    );

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "ParamValue",
        xAxis: xAxis,
        yAxis: yAxis,
        connect: false,
        valueYField: "value2",
        valueXField: "paramDatetime",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}:{valueY}"
        }),
        stroke: am5.color("#716F6F"), // สีเขียว
        fill: am5.color("#716F6F"),   // สีเขียว
      })
    );

    series2.strokes.template.setAll({
      stroke: am5.color("#716F6F"), // สีเขียว
      strokeWidth: 1,
    });

    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "PredictValue",
        xAxis: xAxis,
        yAxis: yAxis,
        connect: false,
        valueYField: "value1",
        valueXField: "paramDatetime",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}:{valueY}"
        }),
        zIndex: 1,
        stroke: am5.color("#00FF00"), // สีแดง
        fill: am5.color("#00FF00"),   // สีแดง
      })
    );

    series1.strokes.template.setAll({
      stroke: am5.color("#00FF00"), // สีแดง
      strokeWidth: 3,
    });

    // series2.set("fill", am5.color("#908E8E"));
    // series1.set("fill", am5.color("#50b300"));

    series2.data.setAll(data);
    series1.data.setAll(data);

    let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {}));

    legend.itemContainers.template.events.on("pointerover", function(e) {
      let itemContainer = e.target;
      let series = itemContainer.dataItem.dataContext;
      chart.series?.each(function(chartSeries) {
        if (chartSeries != series) {
          chartSeries.strokes?.template.setAll({
            strokeOpacity: 0.15,
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 4,
          });
        }
      })
    });

    legend.itemContainers.template.events.on("pointerout", function(e) {
      chart.series?.each(function(chartSeries) {
        chartSeries.strokes?.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 3,
          stroke: chartSeries.get("fill")
        });
      });
    });

    legend.data.setAll(chart.series.values || []);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);



        function getMaxValue(data, field) {
          let max = -Infinity; // เริ่มต้นด้วยค่าที่ต่ำที่สุด
          for (const item of data) {
            const value = item[field] || 0; // ถ้าไม่มีค่า ให้ใช้ 0
            if (value > max) {
              max = value; // อัปเดตค่า max
            }
          }
          console.log("Max:", max);
          return max;
        }

        function getMinValue(data, field) {
          let min = Infinity; // เริ่มต้นด้วยค่าที่มากที่สุด
          for (const item of data) {
            const value = item[field];
            if (value && value < min) { // ตรวจสอบว่าค่าไม่เป็น 0 หรือ undefined/null
              min = value; // อัปเดตค่า min
            }
          }
          console.log("Min:", min === Infinity ? "No valid value" : min);
          return min === Infinity ? null : min; // คืนค่า null ถ้าไม่มีค่า valid
        }
    
    var highlightSeries;

    function addHighlight(data) {
      if (!highlightSeries) {
        highlightSeries = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "y2",
            openValueYField: "y1",
            valueXField: "x2",
            openValueXField: "x1"
          })
        );
        highlightSeries.columns.template.setAll({
          strokeOpacity: 0,
          fillOpacity: 0.5,
        });
      }

      highlightSeries.columns.template.adapters.add("fill", (fill, target) => {
        return target.dataItem?.dataContext?.setting?.fill || fill;
      });
  
      highlightSeries.columns.template.adapters.add("fillOpacity", (fillOpacity, target) => {
        return target.dataItem?.dataContext?.setting?.fillOpacity || fillOpacity;
      });

      highlightSeries.data.push(data);
    }

    let V2Param = getMaxValue(series2.data.values, "value2");
    let V1Predi = getMaxValue(series1.data.values, "value1");
    let maxValue = Math.max(V2Param, V1Predi);
    
    let x1 = null;
    let x2 = null;
    let y1 = getMinValue(series2.data.values,"value2");
    let y2 = maxValue;
    let inHighlight = false;
    let highlightColor = null;
    
    for (let i = 0; i < anomalyData.length; i++) {
      const currentData = anomalyData[i];
     
      const datetime = new Date(currentData.paramDatetime).getTime();
    
      if (currentData.paramValue === null || currentData.paramValue === -1 || currentData.paramValue === -2) {
        console.log("IF")
        if (!inHighlight) {
          x1 = datetime;
          inHighlight = true;

         
          // ตั้งค่าสีตาม paramValue
          if (currentData.paramValue === -1) {
            highlightColor = am5.color(0x9370DB); // สีม่วงอ่อน
          } else if (currentData.paramValue === -2) {
            highlightColor = am5.color(0xD3D3D3); // สีเทาอ่อน
          }
        }
        x2 = datetime;
      } else if (inHighlight) {
        if (x1 > x2) [x1, x2] = [x2, x1]; // ตรวจสอบลำดับเวลา
        console.log("Highlight Color:", highlightColor);
        addHighlight({
          x1,
          x2,
          y1,
          y2,
          setting: {
            fill: highlightColor,
            fillOpacity: 0.3,
          }
        });
        inHighlight = false;
        highlightColor = null;
      }
    }
    
    if (inHighlight) {
      if (x1 > x2) [x1, x2] = [x2, x1];
      addHighlight({
        x1,
        x2,
        y1,
        y2,
        setting: {
          fill: highlightColor,
          fillOpacity: 0.3,
        }
      });
    }


    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);
    

    return () => {
      root.dispose();
    };
  }, [anomalyData, date]);

  return (
    <div
      ref={chartContainerRef}
      style={{ width: "100%", height: "300px" }}
      id={chartContainerRef}
    ></div>
  );
};

export default AnomalyChart;
