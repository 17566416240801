import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Karun = ({ anomalyData}) => {
  const chartContainerRef = useRef(null);
        // console.log('Graph SUM')
  let data = [];

  for (let i in anomalyData) {
    let dataG =
    {
      date: Math.floor((new Date(anomalyData[i].paramDatetime) / 1000) * 1000),
      //  date: new Date(Date.parse(anomalyData[i].paramDatetime)),
      // value1: anomalyData[i].meanVal,
      paramValue: anomalyData[i].paramValue,
      alert: anomalyData[i].alert,
      warning: anomalyData[i].warning,
      // paramcode: anomalyData[i].paramCode,
      // paramUnit: anomalyData[i].paramUnit,
        paramDatetime: Math.floor((new Date(anomalyData[i].paramDatetime) / 1000) * 1000),
      // paramDatetime1: formatDateToTime(anomalyData[i].paramDatetime)
    }
    data.push(dataG)
  }
  data.sort((x,y) => x.paramDatetime - y.paramDatetime)
  // console.log("deata",data)
  

  useEffect(() => {
    let root = am5.Root.new(chartContainerRef.current);

    root.setThemes([am5themes_Animated.new(root)]);

    // root.dateFormatter.setAll({
    //   dateFormat: "yyyy-MM-dd hh:mm:sss",
    //   dateFields: ["date"],
    // })

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      focusable: true,
      panX: true,
      panY: true,
      wheelX: "zoomX",
      wheelY: "zoomY",
      pinchZoomX: true
    }));

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        // maxDeviation: 0.1,
        baseInterval: {
          timeUnit: "minute",
          count: 5,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 60,
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root,{}),
        // tooltipDateFormat: "dd MM yyyy",
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 5,
        min: 0,
        max: 20,
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(root, { pan: "zoom",  minGridDistance: 60, }),
      })
    );
  


    let series = chart.series.push(am5xy.LineSeries.new(root, {
      // name: "Mean",
      minBulletDistance: 10,
      connect: false,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "paramValue",
      valueXField: "paramDatetime",
      tooltip: am5.Tooltip.new(root, {}),
    }));
    series.get("tooltip").label.set("text", "{valueY}")
    series.data.setAll(data);
    series.strokes.template.setAll({
      strokeWidth: 2
    });


let series2 = chart.series.push(am5xy.LineSeries.new(root, {
  name: "Alert",
  xAxis: xAxis,
  yAxis: yAxis,
  valueYField: "alert",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {
    labelText: "{name}:{valueY}"
  }),
  stroke: am5.color("#FF0C0C "), // สีแดง
  fill: am5.color("#FF0C0C "),   // สีแดง
}));
series2.strokes.template.setAll({
  strokeDasharray: [8, 8],
  stroke: am5.color("#FF0C0C "), // สีแดง
  strokeWidth: 2,
});
//
let series3 = chart.series.push(am5xy.LineSeries.new(root, {
  xAxis: xAxis,
  name: "Warning",
  yAxis: yAxis,
  valueYField: "warning",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {
    labelText: "{name}:{valueY}",
  }),
  stroke: am5.color("#F78531  "), 
  fill: am5.color("#F78531  "),  
}));
series3.strokes.template.setAll({
  strokeDasharray: [8, 8],
  stroke: am5.color("#F78531  "), 
  strokeWidth: 2
});

let series4 = chart.series.push(am5xy.LineSeries.new(root, {
  xAxis: xAxis,
  name: " Criteria < MW",
  yAxis: yAxis,
  valueYField: "warning",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {
    labelText: "{name}:{valueY}",
  }),
  stroke: am5.color("#D3D3D3"), 
  fill: am5.color("#D3D3D3"),  
}));
series4.strokes.template.setAll({
  strokeDasharray: [8, 8],
  stroke: am5.color("#D3D3D3"), 
  strokeWidth: 2
});

let series5 = chart.series.push(am5xy.LineSeries.new(root, {
  name: "Sensor Bad",
  minBulletDistance: 10,
  connect: false,
  xAxis: xAxis,
  yAxis: yAxis,
  valueYField: "Sensor Bad",
  valueXField: "paramDatetime",
  tooltip: am5.Tooltip.new(root, {
     labelText: "{name}:{valueY}"
  }),
  stroke: am5.color("#9370DB"), 
  fill: am5.color("#9370DB"),  
}));
series5.get("tooltip").label.set("text", "{valueY}")
series5.data.setAll(data);
series5.strokes.template.setAll({
  strokeWidth: 2
});

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY"
    }));
    cursor.lineY.set("visible", false);

    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    // chart.set("scrollbarY", am5.Scrollbar.new(root, {
    //   orientation: "vertical",
    // }));

    // chart.yAxes.getIndex(0).set("start", 1); // เริ่มที่ตำแหน่ง 0
    // chart.yAxes.getIndex(0).set("end", 0);   // จบที่ตำแหน่ง 1 (100%)

    let rangeDataItem = yAxis.makeDataItem({
      value: 0,
      endValue: anomalyData[0].warning,
    });
    let rangeDataItem2 = yAxis.makeDataItem({
      value: anomalyData[0].warning,
      endValue: anomalyData[0].alert,
    });
    let rangeDataItem3 = yAxis.makeDataItem({
      value: anomalyData[0].alert,
      endValue: 100,
    });

    let yellow = "rgba(255,207,59,1)";
    let lightYellow = "rgba(255,207,59,0.2)";
    let red = "rgba(255, 56, 56, 1)";
    let lightRed = "rgba(255, 56, 56, 0.2)";
    let green = "rgba(106, 185, 111, 1)";
    let lightGreen = "rgba(106, 185, 111, 0.2)";

    let range = series.createAxisRange(rangeDataItem);
    let range2 = series.createAxisRange(rangeDataItem2);
    let range3 = series.createAxisRange(rangeDataItem3);

    range.strokes.template.setAll({
      stroke: green,
    });

    range.fills.template.setAll({
      fill: lightGreen,
      fillOpacity: 0.6,
      visible: true,
    });

    range2.strokes.template.setAll({
      stroke: yellow,
    });
    range2.fills.template.setAll({
      fill: lightYellow,
      fillOpacity: 0.6,
      visible: true,
    });
    range3.strokes.template.setAll({
      stroke: red,
    });
    range3.fills.template.setAll({
      fill: lightRed,
      fillOpacity: 0.6,
      visible: true,
    });

      series.set("fill", am5.color(0x50b300));
     series2.set("fill", am5.color("#FE0909"));
     series3.set("fill", am5.color("#EE8C00"));
    // series4.set("fill", am5.color(0xff0000));




     series.data.setAll(data);
    series2.data.setAll(data);
    series3.data.setAll(data);

    let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {

    }));

    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);

    var highlightSeries;

    function addHighlight(data) {
      if (!highlightSeries) {
        highlightSeries = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "y2",
            openValueYField: "y1",
            valueXField: "x2",
            openValueXField: "x1"
          })
        );
        highlightSeries.columns.template.setAll({
          strokeOpacity: 0,
          fillOpacity: 0.5,
        });
      }
      highlightSeries.data.push(data);
    }
    
    let x1 = null;
    let x2 = null;
    let y1 = 0; // ค่าต่ำสุดในแกน Y
    let y2 = 100; // ค่าสูงสุดในแกน Y
    let inHighlight = false;
    let highlightColor = null;
    
    for (let i = 0; i < anomalyData.length; i++) {
      const currentData = anomalyData[i];
      const datetime = new Date(currentData.paramDatetime).getTime();
    
      if (currentData.paramValue == null || currentData.paramValue == -1 || currentData.paramValue == -2) {
        if (!inHighlight) {
          x1 = datetime;
          inHighlight = true;
    
          // ตั้งค่าสีตาม paramValue
          if (currentData.paramValue === -1) {
            highlightColor = am5.color("#F78531"); // สีม่วงอ่อน
          } else if (currentData.paramValue === -2) {
            highlightColor = am5.color("#D3D3D3"); // สีเทาอ่อน
          }
        }
        x2 = datetime;
      } else if (inHighlight) {
        if (x1 > x2) [x1, x2] = [x2, x1]; // ตรวจสอบลำดับเวลา
    
        addHighlight({
          x1,
          x2,
          y1,
          y2,
          setting: {
            fill: highlightColor,
            fillOpacity: 0.3,
          }
        });
        inHighlight = false;
        highlightColor = null;
      }
    }
    
    if (inHighlight) {
      if (x1 > x2) [x1, x2] = [x2, x1];
      addHighlight({
        x1,
        x2,
        y1,
        y2,
        setting: {
          fill: highlightColor,
          fillOpacity: 0.3,
        }
      });
    }
    
    return () => {
      root.dispose();
    };
  }, [anomalyData]);

  return (
    <div
      ref={chartContainerRef}
      style={{ width: "100%", height: "300px" }}
      id={chartContainerRef}></div>
  );
};

export default Karun;



function formatDateToTime(datetime) {
  let date = new Date(datetime);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  
  // Add leading zeros to minutes and seconds if they are less than 10
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  
  return hours + ':' + minutes + ':' + seconds;
}